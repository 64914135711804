import React from "react";
import { LoadingOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Form, Input, Row, Col, Select, Button, InputNumber, Alert } from "antd";
import api from '../api';
const { Option } = Select;

/*export class AddProductView extends React.Component {
+
+  state = {
+    manufacturers: null
+  }
+
+  componentDidMount() {
+    api
+      .get(`/api/manufacturers`)
+      .then(response => response.data)
+      .then(result => this.setState({ manufacturers: result.data }));
+  }
+
+  onSubmit = (newProduct) => {
+    api.post(`/api/products`, newProduct)
+  }
+
+  render() {
+    <AddProductForm product={} manufacturers={this.state.manufacturers} onSubmit={this.onSubmit} />
+  }
+}*/

export class AddProductForm extends React.Component {
  state = {
    manufacturers: null,
  };

  formRef = React.createRef();

  handleChange = (name, value,) => {
    this.setState({
      [name]: value,
      saveChangeDelivery: false
    })
  }

  onReset = () => {
    this.formRef.current.resetFields();

  }

  handleSubmit = (values) => {
    //this.props.onSubmit(newProduct);
    api
      .post(`/api/products`, values)
      .then(response => {

        this.setState({
          status: response.status,
          successMessage: 'Produkt został dodany',
        })
      });
  }

  /*
    handleSubmit = e => {
      e.preventDefault();
  
      const { manufacturer_code, code, name, ean, size_w, size_h, size_l, weight } = this.state
  
      if (!(manufacturer_code && code && name && ean && size_w && size_h && size_l && weight))
        return;
  
      const newProduct = {
        manufacturer_code,
        code,
        name,
        ean,
        size_w,
        size_h,
        size_l,
        weight
      }
  
      this.props.onSubmit(newProduct);
  
      api
        .post(`/api/products`, newProduct)
        .then(response => this.setState({ 
          status: response.status,
          successMessage: 'Produkt został dodany',
          manufacturer_code: null,
          code: "",
          name: "",
          ean: null,
          size_w: null,
          size_h: null,
          size_l: null,
          weight: null,
          valid: true,
          wasSent: true
        }))
        .catch(error => this.setState({
          status: error.response.status,
          errorMessage: error.response.data.message,
        }));
    }
  
  */
  componentDidMount() {
    api
      .get(`/api/manufacturers`)
      .then(response => response.data)
      .then(result => this.setState({ manufacturers: result.data }));
  }

  render() {
    if (this.state.manufacturers == null)
      return <LoadingOutlined />;

    const validateMessages = {
      required: '${label} jest wymagane',
      types: {
        number: 'wprowadź liczbę',
      },
      number: {
        min: '${label} musi być większe od 0',
      },
    };

    return (
      <>
        <Form
          name="control-ref"
          validateMessages={validateMessages}
          onFinish={this.handleSubmit}
          ref={this.formRef}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={['manufacturer_code']}
                label="Producent"
                rules={[{ required: true, }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Wybierz producenta"
                  optionFilterProp="children"
                  onChange={this.handleChange}
                >
                  {this.state.manufacturers.map(m => (
                    <Option key={m.code} value={m.code}>{m.name}</Option>))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={['code']}
                label="Kod"
                rules={[{ required: true, }]}
              >
                <Input
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={['name']}
                label="Nazwa"
                rules={[{ required: true, }]}>
                <Input
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={['ean']}
                label="Kod EAN"
                rules={[{ required: true }]}>
                <InputNumber
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={14} style={{ marginBottom: "10px", border: "1px solid lightgrey" }}>
              <Row>
                <div style={{ color: "black", fontSize: "16px" }}> Wymiary opakowania [cm]:</div>
              </Row>
              <Col span={24}>
                <Form.Item
                  label="Szerokość"
                  name={['size_w']}
                  rules={[
                    { required: true, },
                    {
                      type: 'number',
                      min: 0.001
                    }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                  />
                </Form.Item>
                <Form.Item
                  label="Wysokość"
                  name={['size_h']}
                  rules={[
                    { required: true, },
                    {
                      type: 'number',
                      min: 0.001
                    }]}
                >
                  < InputNumber
                    style={{ width: "100%" }}
                    min={0}
                  />
                </Form.Item>
                <Form.Item
                  label="Długość"
                  name={['size_l']}
                  rules={[
                    { required: true, },
                    {
                      type: 'number',
                      min: 0.001
                    }]}
                >
                  < InputNumber
                    style={{ width: "100%" }}
                    min={0}

                  />
                </Form.Item>
              </Col>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Waga [kg]"
                name={['weight']}
                rules={[
                  { required: true, },
                  {
                    type: 'number',
                    min: 0.001
                  }]}
              >
                <InputNumber
                  style={{ width: "50%" }}
                  min={0} step={0.1}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col span={12} offset={9}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="finish"
              >
                Dodaj Produkt
              </Button>
            </Form.Item>
          </Col>
          <Col span={24}>
            {this.state.successMessage ? <Alert message={this.state.successMessage} type="success" /> : ""}
            {this.state.errorMessage ? <Alert message={this.state.errorMessage} type="error" /> : ""}
          </Col>
        </Form>
      </>
    );
  }
}