import React from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { Alert, Button, Card, Divider, Row, Col, message } from 'antd';

import api from '../api';

class ImportWithoutRouter extends React.Component {

    constructor() {
        super();

        this.state = {};
    }
        
    componentDidMount() {
    
        const params = queryString.parse(this.props.location.search);

        if(params.token) {
            localStorage.setItem('allegro_token', params.token)
        }
    }

    render() {
        const allegroToken = localStorage.getItem('allegro_token');

        return (
            <Row gutter={16}>
                <Col span={6}>
                    <Card title="Allegro">
                        {allegroToken ? 
                        <>
                            <BackendProcessingButton
                                label="Importuj oferty"
                                url="/api/import/allegro/offers"
                                headers={{
                                    'Allegro-Token': allegroToken
                                }} />
                            <Divider />
                            <BackendProcessingButton
                                label="Importuj zamówienia"
                                url="/api/import/allegro/checkout-forms"
                                headers={{
                                    'Allegro-Token': allegroToken
                                }} />
                        </> :
                        <a href="/auth/provider">Zaloguj do Allegro</a>
                        }
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="Sklep">
                        <BackendProcessingButton
                            label="Importuj"
                            url="/api/import/clickshop"
                        />
                    </Card>
                </Col>
            </Row>
        );
    }
}

const Import = withRouter(ImportWithoutRouter);

export { Import };

class BackendProcessingButton extends React.Component {
    constructor() {
        super();

        this.state = {
            isLoading: false,
            message: ''
        }

        this.handleClick = this.handleClick.bind(this);
    };

    handleClick() {
        this.setState({ isLoading: true, message: '' });

        api.post(this.props.url, {}, {
            headers: this.props.headers
        })
            .then(response => response.data)
            .then(response => {
                this.setState({
                    isLoading: false,
                    message: response.message
                });
                message.success(response.message);
            })
            .catch(response => {
                this.setState({
                    isLoading: false,
                    message: response.message
                });
                message.error(response.message);
            });
    }

    render() {
        return (<>
            <Button
                block
                loading={this.state.isLoading}
                onClick={this.handleClick}>
                {this.props.label}
            </Button>
            {this.state.message && <Alert message={this.state.message} style={{ marginTop: '10px', whiteSpace: 'pre-line' }} />}
        </>)
    }
}