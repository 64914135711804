import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Table, Card, Descriptions } from 'antd';
import api from '../api';

const columns = [
    {
        title: '#',
        render: (_, row, index) => (index + 1)
    },
    {
      title: 'Kod produktu',
      dataIndex: 'product_code',
      key: 'produce_code'
    },
    {
        title: 'Nazwa produktu',
        dataIndex: 'product_name',
        key: 'product_name'
    },
    {
        title: 'Cena netto',
        dataIndex: 'price_nett',
        key: 'price_nett'
    },
    {
        title: 'VAT',
        dataIndex: 'price_vat',
        key: 'price_vat'
    },
    {
        title: 'Cena brutto',
        dataIndex: 'price_gross',
        key: 'price_gross'
    },
    {
        title: 'Ilość',
        dataIndex: 'count',
        key: 'count'
    },
    {
        title: 'Wartość netto',
        dataIndex: 'total_nett',
        key: 'total_nett'
    },
    {
        title: 'VAT',
        dataIndex: 'total_vat',
        key: 'total_vat'
    },
    {
        title: 'Wartość brutto',
        dataIndex: 'total_gross',
        key: 'total_gross'
    },
];

class DeliveryItemsWithoutRouter extends React.Component {
    
    componentDidMount() {
    
        const { id } = this.props.match.params;

        api
            .get(`/api/deliveries/${id}`)
            .then(response => response.data)
            .then(result => this.setState({ details: result.data }));

        api
          .get(`/api/deliveries/${id}/products`)
          .then(response => response.data)
          .then(result => this.setState({ products: result.data }));
    }

    render() {
        if(this.state == null)
            return <LoadingOutlined />;

        const { details, products} = this.state;

        if(!details || !products)
            return <LoadingOutlined />;

        return (<>
            <Card>
                <Descriptions title="Szczegóły dostawy" bordered size="small" column="4" extra={<Link to={'/deliveries/' + this.props.match.params.id + '/edit'}>Edycja</Link>}>
                    <Descriptions.Item key="date" label="Data">{new Date(details.date).toISOString().substring(0, 10)}</Descriptions.Item>
                    <Descriptions.Item key="invoice_no"label="Faktura">{details.invoice_no}</Descriptions.Item>
                    <Descriptions.Item key="supplier_name"label="Dostawca">{details.supplier_name}</Descriptions.Item>
                    <Descriptions.Item key="total_nett" label="Wartość netto">{details.total_nett}</Descriptions.Item>
                    <Descriptions.Item key="total_vat" label="VAT">{details.total_vat}</Descriptions.Item>
                    <Descriptions.Item key="total_gross" label="Wartość brutto">{details.total_gross}</Descriptions.Item>
                </Descriptions>
            </Card>
            <br />
            <Card>
                <Table
                    pagination={false}
                    dataSource={this.state.products} 
                    columns={columns} 
                    size="small"
                    rowKey="product_id" />
            </Card>
        </>);
    }
}

const DeliveryItems = withRouter(DeliveryItemsWithoutRouter);

export { DeliveryItems };
