import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from 'react-router-dom';

import {
  DatabaseOutlined,
  DownloadOutlined,
  HomeOutlined,
  ImportOutlined,
  LogoutOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import { Button, Layout, Menu, PageHeader, Drawer } from 'antd';

import {
  AddProductForm,
  Deliveries,
  DeliveryItems,
  DeliveryAddView,
  DeliveryEditView,
  Home,
  Import,
  LogIn,
  Product,
  Products,
  Sales
} from './Components';

import '@ant-design/compatible/assets/index.css';
import 'antd/dist/antd.css';
import './App.css';

class App extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      addProductDrawerOpen: false
    };
  }

  render() {
    const addButtons = [
      <Button key="add-product" icon={<PlusCircleOutlined />} onClick={() => this.setState({ addProductDrawerOpen: true })}>Dodaj produkt</Button>,
      <Link to="/deliveries/add">
        <Button key="add-delivery" icon={<PlusCircleOutlined />}>Dodaj dostawę</Button>
      </Link>,
      <Button key="logout" shape="circle" icon={<LogoutOutlined />} type="link" onClick={() => {
        localStorage.removeItem('token');
        window.location.reload(true);
      }}>Wyloguj</Button>
    ];

    return (
      <Router>
        {!localStorage.getItem('token') && <Redirect to="/login" />}
        <Switch>
          <Route path="/login"><LogIn /></Route>
          <Route path="*">
            <Drawer
              title="Dodaj produkt"
              visible={this.state.addProductDrawerOpen}
              onClose={() => this.setState({ addProductDrawerOpen: false })}
              width={720}
              >
              <AddProductForm />
            </Drawer>
            <Layout>
              <Layout.Sider
                style={{
                  overflow: 'auto',
                  height: '100vh',
                  position: 'fixed',
                  left: 0,
                }}
              >
                <div className="logo" />
                <Menu theme="dark" mode="inline" defaultSelectedKeys={['/']}>
                  <Menu.Item key="/">
                    <Link to="/">
                      <HomeOutlined />
                      <span className="nav-text">Home</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/products">
                    <Link to="/products">
                      <DatabaseOutlined />
                      <span className="nav-text">Produkty</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/deliveries">
                    <Link to="/deliveries">
                      <DownloadOutlined />
                      <span className="nav-text">Dostawy</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/sales">
                    <Link to="/sales">
                      <UploadOutlined />
                      <span className="nav-text">Sprzedaż</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/import">
                    <Link to="/import">
                      <ImportOutlined />
                      <span className="nav-text">Import danych</span>
                    </Link>
                  </Menu.Item>
                </Menu>
              </Layout.Sider>
              <Layout style={{ marginLeft: 200, minHeight: '100vh' }}>
                <Layout.Header style={{ background: '#fff', padding: 0 }}>
                  <Switch>
                    <Route path="/products/:id"><PageHeader title="Szczegóły produktu" extra={addButtons} onBack={() => window.history.back()} /></Route>
                    <Route path="/products"><PageHeader title="Produkty" extra={addButtons} /></Route>
                    <Route path="/deliveries/add"><PageHeader title="Nowa dostawa" extra={addButtons} onBack={() => window.history.back()} /></Route>
                    <Route path="/deliveries/:id/edit"><PageHeader title="Edycja dostawy" extra={addButtons} onBack={() => window.history.back()} /></Route>
                    <Route path="/deliveries/:id"><PageHeader title="Szczegóły dostawy" extra={addButtons} onBack={() => window.history.back()} /></Route>
                    <Route path="/deliveries"><PageHeader title="Dostawy" extra={addButtons} /></Route>
                    <Route path="/sales"><PageHeader title="Sprzedaż" extra={addButtons} /></Route>
                    <Route path="/import"><PageHeader title="Import danych" extra={addButtons} /></Route>
                    <Route path="/"><PageHeader title="" extra={addButtons} /></Route>
                  </Switch>
                </Layout.Header>
                <Layout.Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                  <Switch>
                    <Route path="/products/:id"><Product /></Route>
                    <Route path="/products"><Products /></Route>
                    <Route path="/deliveries/add"><DeliveryAddView /></Route>
                    <Route path="/deliveries/:id/edit"><DeliveryEditView /></Route>
                    <Route path="/deliveries/:id"><DeliveryItems /></Route>
                    <Route path="/deliveries"><Deliveries /></Route>
                    <Route path="/sales"><Sales /></Route>
                    <Route path="/import"><Import /></Route>
                    <Route path="/"><Home /></Route>
                  </Switch>
                </Layout.Content>
              </Layout>
            </Layout>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
