import React from 'react';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Tag, Table, Card } from 'antd';

import api from '../api';

const columns = [
    {
        title: 'Data',
        dataIndex: 'date',
        key: 'date'
    },
    {
        title: 'Źródło',
        dataIndex: 'source',
        key: 'source'
    },
    {
        title: 'Numer zamówienia',
        dataIndex: 'external_order_id',
        key: 'external_order_id',
        render: (_, row) => <>
            <Link to={'/sales/' + row.sale_id}>{row.external_order_id}</Link>
            {row.is_cancelled && <Tag style={{float: 'right'}} color="red">Anulowane</Tag>}
        </>
    },
    {
        title: 'Kod produktu',
        dataIndex: 'product_code',
        key: 'product_code',
        render: (_, row) => <Link to={'/products/' + row.product_id}>{row.product_code}</Link>
    },
    {
        title: 'Nazwa produktu',
        dataIndex: 'product_name',
        key: 'product_name',
        render: (_, row) => <Link to={'/products/' + row.product_id}>{row.product_name}</Link>
    },
    {
        title: 'Ilość',
        dataIndex: 'count',
        key: 'count'
    },
    {
        title: 'Cena',
        dataIndex: 'price',
        key: 'price'
    }
];

export class Sales extends React.Component {

    componentDidMount() {
        api
            .get(`/api/sale-products`)
            .then(response => response.data)
            .then(result => this.setState(result));
    }

    render() {
        if (this.state == null)
            return <LoadingOutlined />;

        return <Card><Table
            dataSource={this.state.data}
            columns={columns}
            size="small"
            rowKey="id" /></Card>;
    }
}
