import React from 'react';
import { withRouter } from 'react-router-dom';
import api from '../api';
import { DeliveryForm } from './DeliveryAddForm';

class DeliveryEditViewInternal extends React.Component {
    state = {};

    reloadData = () => {
        const { id } = this.props.match.params;

        api
            .get(`/api/deliveries/${id}`)
            .then(response => response.data)
            .then(result => this.setState({ details: result.data }));

        api
          .get(`/api/deliveries/${id}/products`)
          .then(response => response.data)
          .then(result => this.setState({ products: result.data }));
    }

    componentDidMount() {
        this.reloadData();
    }

    render() {
        if(!this.state.details || !this.state.products)
            return null;

        return <DeliveryForm          
            id={this.props.match.params.id}
            initialValues={{
                ...this.state.details,
                products: this.state.products
            }}
            onSave={this.reloadData}
            />;
    }
}

const DeliveryEditView = withRouter(DeliveryEditViewInternal);

export { DeliveryEditView };