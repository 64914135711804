import React from 'react';
import { DeliveryForm } from './DeliveryAddForm';
import { Redirect } from 'react-router-dom';

class DeliveryAddView extends React.Component {
    state = {}

    render() {
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} />
        }
        return <DeliveryForm
            id={null}
            initialValues={null}
            onSave={(delivery_id) => this.setState({redirectTo: `/deliveries/${delivery_id}/edit`})}
             />
    }
}

export { DeliveryAddView };