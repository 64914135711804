import React from 'react';
import { Link } from 'react-router-dom';
import { BarcodeOutlined, LoadingOutlined, ReadOutlined, WarningTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, Card, Select, Button, Checkbox, Input, Tooltip } from 'antd';

import api from '../api';

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
    },
    {
        title: 'Producent',
        dataIndex: 'manufacturer_name',
        key: 'manufacturer_name'
    },
    {
        title: 'Kod',
        dataIndex: 'code',
        key: 'code'
    },
    {
        title: 'Nazwa',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Ilość',
        dataIndex: 'count',
        key: 'count'
    },
    {
        title: 'Ilość Clickshop',
        dataIndex: 'clickshop_count',
        key: 'clickshop_count',
        render: (_, row) => <>
            {row.clickshop_count}
            &nbsp;
            {row.clickshop_count != row.count && <WarningTwoTone twoToneColor="#eb2f96" />}
        </>
    },
    {
        title: 'EAN',
        key: 'ean',
        render: (_, row) => row.ean && <><BarcodeOutlined /> {row.ean}</>
    },
    {
        title: 'Notatka',
        key: 'notes',
        render: (_, row) => row.notes && <Tooltip title={row.notes}><ReadOutlined /></Tooltip>
    },
    {
        title: '',
        key: 'details',
        render: (_, row) => <Link to={'/products/' + row.id}>Szczegóły</Link>
    },
    {
        title: '',
        key: 'edit',
        render: (_, row) => <Button type="link">Edycja</Button>
    }
];

const getUniqueManufacturers = (products) => Array.from(new Set(products.map(p => p.manufacturer_name)));

export class Products extends React.Component {

    constructor() {
        super();

        this.state = {
            search: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchEanChange = this.handleSearchEanChange.bind(this);
    }

    handleChange(value) {
        this.setState({ selectedManufacturer: value });
    }

    handleCheckboxChange(e) {
        this.setState({ showWithoutEan: e.target.checked });
    }

    handleSearchChange(e) {
        this.setState({ search: e.target.value });
    }

    handleSearchEanChange(e) {
        this.setState({ searchEan: e.target.value });
    }

    render() {
        if (this.state.products == null || this.state.counts == null)
            return <LoadingOutlined />;

        let { products, counts, selectedManufacturer } = this.state;

        products = products.map(p => ({
            ...p,
            count: (counts.find(c => c.product_id === p.id) || {}).sum
        }));

        const withoutEan = products.reduce((s, i) => s + (!i.ean ? 1 : 0), 0);

        const manufacturers = getUniqueManufacturers(products);
        manufacturers.unshift('-');

        let filtered = (!selectedManufacturer || selectedManufacturer === '-')
            ? products
            : products.filter(d => d.manufacturer_name === selectedManufacturer);

        if (this.state.showWithoutEan) {
            filtered = filtered.filter(p => !p.ean);
        }

        if (this.state.search) {
            filtered = filtered.filter(p => p.name.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1);
        }

        if (this.state.searchEan) {
            filtered = filtered.filter(p => p.ean != null).filter(p => p.ean.toLowerCase().indexOf(this.state.searchEan.toLowerCase()) > -1);
        }

        return (
            <>
                <Card>
                    <Form layout="inline">
                        <Form.Item label="Dostawca">
                            <Select showSearch onChange={this.handleChange} style={{ width: 180 }}>
                                {manufacturers.map(m => <Select.Option key={m}>{m}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Nazwa">
                            <Input onChange={this.handleSearchChange} value={this.state.search} />
                        </Form.Item>
                        <Form.Item label="EAN">
                            <Input onChange={this.handleSearchEanChange} value={this.state.searchEan} />
                        </Form.Item>
                        <Form.Item label={`Bez kodu EAN (${withoutEan})`}>
                            <Checkbox onChange={this.handleCheckboxChange} />
                        </Form.Item>
                    </Form>
                    <Table
                        dataSource={filtered}
                        columns={columns}
                        footer={() => `Widocznych produktów: ${filtered.length} z ${products.length}`}
                        size="small"
                        rowKey="id" />
                </Card>
            </>
        );
    }

    componentDidMount() {
        api
            .get(`/api/products`)
            .then(response => response.data)
            .then(result => this.setState({ products: result.data }));

        api
            .get(`/api/products/counts`)
            .then(response => response.data)
            .then(result => this.setState({ counts: result.data }));
    }
}
