import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Tag, Card, Table } from 'antd';

import api from '../api';

const columns = [
    {
        title: 'Data',
        dataIndex: 'date',
        key: 'date',
        render: date => new Date(date).toISOString().substring(0, 10)
    },
    {
        title: 'Źródło',
        dataIndex: 'source',
        key: 'source'
    },
    {
        title: 'Numer zamówienia',
        dataIndex: 'external_order_id',
        key: 'external_order_id',
        render: (_, row) => <>
            {row.source === 'Clickshop' && <a target="_blank" href={'https://oak-toys.pl/admin/orders/view/id/' + row.external_order_id} rel="noopener noreferrer">{row.external_order_id}</a>}
            {row.source === 'Allegro' && <a target="_blank" href={'https://allegro.pl/moje-allegro/sprzedaz/zamowienia/' + row.external_order_id} rel="noopener noreferrer">{row.external_order_id}</a>}
            {row.source === 'Mail' && row.external_order_id}
            {row.is_cancelled && <Tag style={{float: 'right'}} color="red">Anulowane</Tag>}
        </>
    },
    {
        title: 'Ilość',
        dataIndex: 'count',
        key: 'count',
        render: (_, row) => row.is_cancelled ? <s>{row.count}</s> : row.count
    },
    {
        title: 'Cena',
        dataIndex: 'price',
        key: 'price'
    }
];

export class ProductSales extends React.Component {
    
    componentDidMount() {
        api.get(`/api/product/${this.props.productId}/sales`)
          .then(response => response.data)
          .then(result => this.setState(result));
    }

    render() {
        if(this.state == null)
            return <LoadingOutlined />;

        const total = this.state.data.reduce((s,i) => s + (i.is_cancelled ? 0 : i.count), 0);

        return (
            <Card title="Sprzedaż" size="small" extra={<Tag>{total}</Tag>}>
                <Table 
                    dataSource={this.state.data} 
                    columns={columns} 
                    size="small"
                    rowKey="id" />
            </Card>
        );
    }
}
