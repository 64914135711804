import React from 'react';
import { Statistic, Card, Row, Col } from 'antd';

import api from '../api';

export class Home extends React.Component {
    
    constructor() {
        super();

        this.state = {
            products: 0,
            deliveries: 0 
        }
    };

    componentDidMount() {
        api.get(`/api/statistics`)
            .then(response => response.data)
            .then(result => this.setState(result.data));
    }

    render() {
        return (
            <Row gutter={16}>
                <Col span={6}>
                    <Card>
                        <Statistic
                        title="Produkty"
                        value={this.state.products}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                        title="Dostawy"
                        value={this.state.deliveries}
                        />
                    </Card>
                </Col>
            </Row>
        );
    }
}
