import React from 'react';
import { Redirect } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Card } from 'antd';

import api from '../api';

export class LogIn extends React.Component {

    constructor() {
        super();
        this.state = {
            inProgress: false,
            username: null,
            password: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ inProgress: true });

        api.post('/api/login', {
            username: this.state.username,
            password: this.state.password
        })
            .then(response => response.data)
            .then(response => {
                if (response.success) {
                    localStorage.setItem('token', response.token);
                    this.setState({ redirectTo: '/', inProgress: false });
                } else {
                    this.setState({ inProgress: false });
                }
            })
            .catch(() => {
                this.setState({ inProgress: false });
            });
    }

    render() {
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} />
        }

        return (
            <Row type="flex" justify="center" align="middle" style={{ height: '100vh' }}>
                <Col xs={20} sm={16} md={12} lg={8} xl={4}>
                    <Card>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Item>
                                <Input
                                    prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="Username"
                                    onChange={(e) => this.setState({ username: e.target.value })} />
                            </Form.Item>
                            <Form.Item>
                                <Input
                                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="password"
                                    placeholder="Password"
                                    onChange={(e) => this.setState({ password: e.target.value })} />
                            </Form.Item>
                            <Button type="primary" htmlType="submit" block loading={this.state.inProgress}>
                                Zaloguj
                            </Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        );
    }
}