import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Tag, Card, Table } from 'antd';

import api from '../api';

const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
        title: 'Data dostawy',
        dataIndex: 'date',
        key: 'date',
        render: date => new Date(date).toISOString().substring(0, 10)
    },
    {
        title: 'Numer faktury',
        dataIndex: 'invoice_no',
        key: 'invoice_no'
    },
    {
        title: 'Ilość',
        dataIndex: 'count',
        key: 'count'
    },
    {
        title: 'Cena netto',
        dataIndex: 'price_nett',
        key: 'price_nett'
    },
    {
        title: 'Cena netto x 1.722',
        dataIndex: 'price_nett',
        key: 'price_nett',
        render: price_nett => (price_nett*1.722).toFixed(2)

    }
];

export class ProductDeliveries extends React.Component {
    
    componentDidMount() {
        api.get(`/api/product/${this.props.productId}/deliveries`)
          .then(response => response.data)
          .then(result => this.setState(result));
    }

    render() {
        if(this.state == null)
            return <LoadingOutlined />;

        const total = this.state.data.reduce((s,i) => s + i.count, 0);

        return (
            <Card title="Dostawy" size="small" extra={<Tag>{total}</Tag>}>
                <Table 
                    dataSource={this.state.data} 
                    columns={columns} 
                    size="small"
                    rowKey="id"
                    scroll={{ y: 240 }} />
            </Card>
        );
    }
}
