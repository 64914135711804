import React from 'react';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Table, Card } from 'antd';

import api from '../api';

const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
        title: 'Data dostawy',
        dataIndex: 'date',
        key: 'date',
        render: date => new Date(date).toISOString().substring(0, 10)
    },
    {
        title: 'Numer faktury',
        dataIndex: 'invoice_no',
        key: 'invoice_no'
    },
    {
        title: 'Dostawca',
        dataIndex: 'supplier_name',
        key: 'supplier_name'
    },
    {
        title: 'Netto',
        dataIndex: 'total_nett',
        key: 'total_nett'
    },
    {
        title: 'VAT',
        dataIndex: 'total_vat',
        key: 'total_vat'
    },
    {
        title: 'Brutto',
        dataIndex: 'total_gross',
        key: 'total_gross'
    },
    {
        title: '',
        key: 'details',
        render: (_, row) => <Link to={'/deliveries/' + row.id}>Szczegóły</Link>
    },
    {
        title: '',
        key: 'edit',
        render: (_, row) => <Link to={'/deliveries/' + row.id + '/edit'}>Edycja</Link>
    }
];

export class Deliveries extends React.Component {
    
    componentDidMount() {
        api
            .get('/api/deliveries')
            .then(response => this.setState(response.data));
    }

    render() {
        if(this.state == null)
            return <LoadingOutlined />;

        return <Card><Table 
            dataSource={this.state.data} 
            columns={columns} 
            size="small"
            rowKey="id" /></Card>;
    }
}
