import axios from 'axios';

const instance = axios.create({
    baseURL: `/`
});

instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    config.headers['Allegro-Token'] = localStorage.getItem('allegro_token');
    return config;
});

instance.interceptors.response.use((response) => {
    return response;
});

export default instance;