import React from 'react';
import { withRouter } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Descriptions, Card, Row, Col, Input, Button } from 'antd';

import { ProductDeliveries } from './ProductDeliveries';
import { ProductSales } from './ProductSales';

import api from '../api';

class ProductWithoutRouter extends React.Component {
    
    constructor(props) {
        super(props);

        this.onNotesChange = this.onNotesChange.bind(this);
        this.onNotesSave = this.onNotesSave.bind(this);
        this.noteRef = React.createRef();
    }

    componentDidMount() {
    
        const { id } = this.props.match.params;

        api.get(`/api/product/${id}`)
          .then(response => response.data)
          .then(result => this.setState({details: result.data, notes: result.data.notes}));
    }

    onNotesChange(e) {
        this.setState({ notes: e.target.value });
    }

    onNotesSave() {
        this.setState({ isNoteSaving: true });

        const { id } = this.props.match.params;

        api.patch(`/api/product/${id}`, { notes: this.state.notes })
          .then(() => this.setState({isNoteSaving: false}));
    }

    render() {
        if(this.state == null)
            return <LoadingOutlined />;

        const { details, isNoteSaving } = this.state;
        const { id } = this.props.match.params;

        return (<>
            <Card>
                <Descriptions title="Szczegóły produktu" bordered size="small">
                    <Descriptions.Item label={details.code}>{details.name}</Descriptions.Item>
                    <Descriptions.Item label="EAN">{details.ean}</Descriptions.Item>
                    <Descriptions.Item label="Rozmiar">{details.size_w} cm x {details.size_h} cm x {details.size_l} cm</Descriptions.Item>
                    <Descriptions.Item label="Waga">{details.weight} kg</Descriptions.Item>
                </Descriptions>
            </Card>
            <br />
            <Row gutter={16} type="flex">
                <Col span={12}><ProductDeliveries productId={id} /></Col>
                <Col span={12}><ProductSales productId={id} /></Col>
            </Row>
            <Row gutter={16} type="flex">
                <Col span={12}>
                    <Card title="Notatka" size="small" extra={<Button onClick={this.onNotesSave} size="small" type="link" loading={isNoteSaving}>Zapisz</Button>}>
                        <Input.TextArea rows={4} value={this.state.notes} onChange={this.onNotesChange} />
                    </Card>
                </Col>
            </Row>
        </>);
    }
}

const Product = withRouter(ProductWithoutRouter);

export { Product };
