import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Space, Form, InputNumber, Button, Input, DatePicker, Row, Col, Card, Select, message } from 'antd';
import api from '../api';
import moment from 'moment';
const { Option } = Select;

class DeliveryForm extends React.Component {

    state = {};

    formRef = React.createRef();

    formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        }
    };

    render() {
        if (!this.state.products)
            return null;

        return <>
            <Row>
                <Col span={20} offset={2}>
                    <Card>

                        <Form
                            {...this.formItemLayout}
                            name="delivery-form"
                            initialValues={this.mapDbToInitialValues(this.props.initialValues)}
                            onFinish={this.onFinish}
                            ref={this.formRef}>
                            <Form.Item
                                name="date"
                                label="Data faktury"
                                rules={[{ required: true }]}>
                                <DatePicker />
                            </Form.Item>
                            <Form.Item
                                name="invoice_no"
                                label="Numer faktury"
                                rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="supplier_name"
                                label="Dostawca"
                                rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="total_nett"
                                label="Wartość netto"
                                rules={[
                                    { required: true },
                                    {
                                        type: 'number',
                                        min: 0.001,
                                    }
                                ]}>
                                <InputNumber min={0} step={0.01} />
                            </Form.Item>
                            <Form.Item
                                name="total_vat"
                                label="Wartość VAT"
                                rules={[
                                    { required: true },
                                    {
                                        type: 'number',
                                        min: 0.001,
                                    }
                                ]}>
                                <InputNumber min={0} step={0.01} />
                            </Form.Item>
                            <Form.Item
                                name="total_gross"
                                label="Wartość brutto"
                                rules={[
                                    { required: true },
                                    {
                                        type: 'number',
                                        min: 0.001,
                                    }
                                ]}>
                                <InputNumber min={0} step={0.01} />
                            </Form.Item>
                            <Form.List name="products">
                                {this.formListRenderer}
                            </Form.List>
                            <Button type="primary" htmlType="submit">Zapisz</Button>
                        </Form>
                        {/* <br /><br />
                        <Collapse>
                            <Panel header="Raw data">
                                <pre>{JSON.stringify(this.props.initialValues, null, 2)}</pre>
                            </Panel>
                        </Collapse> */}
                    </Card>
                </Col>
            </Row>
        </>;
    }

    componentDidMount() {
        api
            .get(`/api/products`)
            .then(response => response.data)
            .then(result => this.setState({ products: result.data }));
    }

    componentDidUpdate() {
        if(this.formRef.current) {
            this.formRef.current.setFieldsValue(this.mapDbToInitialValues(this.props.initialValues));
        }
    }

    formListRenderer = (fields, { remove, add }) => {
        return <>
            {fields.map(field => (
                <div>
                    <Space key={field.key} align="baseline">
                        <Form.Item
                            {...field}
                            style={{ width: "450px" }}
                            key={"product_id-" + field.key}
                            name={[field.name, 'product_id']}
                            rules={[{ required: true }]}
                            fieldKey={[field.fieldKey, 'product_id']}>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                style={{ width: "100%" }}>
                                {this.state.products.map(m => <Option key={m.id} value={m.id}>{m.name}</Option>)}</Select>
                        </Form.Item>
                        <Form.Item
                            {...field}
                            key={"count-" + field.key}
                            name={[field.name, 'count']}
                            rules={[{ required: true }]}
                            fieldKey={[field.fieldKey, 'count']}>
                            <InputNumber style={{ width: "110px" }} placeholder="Ilość" min={0}></InputNumber>
                        </Form.Item>
                        <Form.Item
                            {...field}
                            key={"price_nett-" + field.key}
                            name={[field.name, 'price_nett']}
                            rules={[{ required: true }]}
                            fieldKey={[field.fieldKey, 'price_nett']}>
                            <InputNumber style={{ width: "110px" }} placeholder="Cena netto" min={0} step={0.01}></InputNumber>
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Space>
                </div>))}
            <Form.Item>
                <Button type="dashed" style={{width: "450px" }} onClick={() => add()} block icon={<PlusOutlined />}>
                    Dodaj produkt
              </Button>
            </Form.Item>
        </>;
    }

    onFinish = (values) => {
        values.id = this.props.id;
        api
            .post(`/api/deliveries`, values)
            .then(response => {
                message.success('Dostawa została zapisana');
                if(this.props.onSave) {
                    this.props.onSave(response.data.data.delivery_id);
                }
            });
    }

    mapDbToInitialValues = (db) => {
        if (!db)
            return null;

        return {
            ...db,
            total_nett: +db.total_nett,
            total_vat: +db.total_vat,
            total_gross: +db.total_gross,
            date: moment(db.date)
        }
    }
}

export { DeliveryForm };